// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vtex-styleguide-9-x-shadowTransition {\n  transition: box-shadow 0.3s ease-in-out;\n}\n\n.vtex-styleguide-9-x-mh100 {\n  max-height: 100%;\n}\n\n.vtex-styleguide-9-x-scrollBar::-webkit-scrollbar {\n  background-color: transparent;\n  width: 8px;\n}\n\n/* background of the scrollbar except button or resizer */\n.vtex-styleguide-9-x-scrollBar::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n/* scrollbar itself */\n.vtex-styleguide-9-x-scrollBar::-webkit-scrollbar-thumb {\n  background-color: #e3e4e6;\n  border-radius: 16px;\n}\n\n/* set button(top and bottom of the scrollbar) */\n.vtex-styleguide-9-x-scrollBar::-webkit-scrollbar-button {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./react/components/Modal/modal.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,UAAU;AACZ;;AAEA,yDAAyD;AACzD;EACE,6BAA6B;AAC/B;;AAEA,qBAAqB;AACrB;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf","sourcesContent":[".shadowTransition {\n  transition: box-shadow 0.3s ease-in-out;\n}\n\n.mh100 {\n  max-height: 100%;\n}\n\n.scrollBar::-webkit-scrollbar {\n  background-color: transparent;\n  width: 8px;\n}\n\n/* background of the scrollbar except button or resizer */\n.scrollBar::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n/* scrollbar itself */\n.scrollBar::-webkit-scrollbar-thumb {\n  background-color: #e3e4e6;\n  border-radius: 16px;\n}\n\n/* set button(top and bottom of the scrollbar) */\n.scrollBar::-webkit-scrollbar-button {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadowTransition": "vtex-styleguide-9-x-shadowTransition",
	"mh100": "vtex-styleguide-9-x-mh100",
	"scrollBar": "vtex-styleguide-9-x-scrollBar"
};
module.exports = ___CSS_LOADER_EXPORT___;
